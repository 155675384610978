import { observable, action, computed, toJS } from 'mobx';
import { RootStore } from '../store';
import autobind from 'autobind-decorator';
import Big from 'big.js';
import {
  logger,
  FORMATS,
  RestaurantUtils,
  cloneDeepSafe,
  GN,
  roundedNum,
  getCurrencyPrecision,
} from '@lib/common';
import exprEval from 'expr-eval';
import {
  Promo,
  FreeItem,
  GenericPromo,
  ConditionTiers,
  ConventionalDiscountPromo,
  NewGenericPromo,
} from '../../ui/modals/cart/type';
import { PromoParser } from '@lib/common';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import _sortBy from 'lodash/sortBy';

Big.RM = 0;

export interface CartState {
  cartCounter: number;
  items: T.Schema.Order.OrderDish[];
  promos: GenericPromo[];
  tip: string;
  promoCodeBlacklist: Array<string>;
  isApplyingPromoCode: boolean;
}

interface PromoForm {
  code: string;
  error: string;
}
interface CartForms {
  promo: PromoForm;
}

function freeQtyFromBuyXWithYFree(
  buyX: number,
  freeY: number,
  receivedQty: number
) {
  let freeQty = 0;

  let purchasedBlockQty = 0;
  let claimedBlockQty = 0;

  for (let i = 0; i < receivedQty; i++) {
    if (purchasedBlockQty < buyX) {
      purchasedBlockQty++;
    } else if (claimedBlockQty < freeY) {
      claimedBlockQty++;
      freeQty++;
    } else if (claimedBlockQty === freeY) {
      purchasedBlockQty = 0;
      claimedBlockQty = 0;
      i--;
    }
  }

  return freeQty;
}

function promoFreeQtyBaseCartItemPrice(item: T.Schema.Order.OrderDish) {
  let price = Big(item.price).div(item.qty || 1);

  const option_sets = item.option_sets;

  for (const os of option_sets) {
    if (!os.inc_price_free_qty_promo) {
      for (const o of os.options) {
        price = price.minus(
          Big(
            RestaurantUtils.dish.calculateOptionSetOptionPrice(
              o,
              os,
              option_sets
            )
          ).times(o.quantity || 0)
        );
      }
    }
  }

  return price.toString();
}

@autobind
export class CartStore {
  @observable s: CartState;
  @observable form: CartForms = {
    promo: {
      code: '',
      error: '',
    },
  };

  store: RootStore;

  constructor(store: RootStore, initialState?: CartState) {
    this.store = store;
    this.s = initialState
      ? observable(initialState)
      : observable({
          cartCounter: 0,
          items: [],
          promos: [],
          tip: '',
          promoCodeBlacklist: [],
          isApplyingPromoCode: false
        });

    // CHECK TO REMOVE PROMO THAT WAS ADDED SERVER SIDE
    if (typeof window !== 'undefined') {
      this.check_promo();
    }
  }

  check_promo = () => {
    const c = this.store.customer.s.item;
    if (this.s.promos.length !== 0) {
      const promo = this.s.promos[0];
      if (promo) {
        const promoParser = new PromoParser(promo);
        const oncePerCustomer = promoParser.isOncePerCustomer();
        if (oncePerCustomer) {
          const oncePerCustomerInvalid = c
            ? c.stats.promos_used.indexOf(promo._id) !== -1
            : !!localStorage.getItem(promo._id);
          if (oncePerCustomerInvalid) {
            this.promoRemove();
          }
        }
      }
    }
  };

  @computed get hasAvailablePromo() {
    const restaurant = this.store.restaurant;
    const promos: GenericPromo[] = [
      ...(restaurant.promos || []).filter(promo => !promo.disabled),
      ...(restaurant.free_item_promos || []),
      ...(restaurant.conventional_discount_promos || []).filter(
        promo => !promo.disabled
      ),
    ];
    return promos.length;
  }

  @computed get itemsWithIDs(): Array<{ dishID: string; cartItemID: number }> {
    const items = this.s.items;
    if (!items) {
      return [];
    }

    return items.map(item => ({
      dishID: item._id,
      cartItemID: item.cart_item_id as number,
    }));
  }

  @computed get itemsInCart() {
    return this.s.items.reduce((a, v) => a + v.qty, 0);
  }

  @computed get totalCart() {
    const total: string = this.s.items.reduce((t, dish) => {
      return Big(t).plus(dish.price).toString();
    }, '0');
    return parseFloat(total || '0');
  }
  //Discount
  @computed get totalCartWithDiscount() {
    const { precision } = this.store.intl.s.currency;
    const total = this.totalCart;
    let discount = this.discount;
    const discounted = parseFloat(
      roundedNum(Number(Big(total).minus(discount).round(precision)), precision).toString()
    );
    return Big(discounted).lt(0) ? 0 : discounted; // IN CASE IT'S LESS THAN 0
  }
  //Fee
  @computed get totalCartWithFees() {
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);
    const total = this.totalCartWithDiscount;
    const fees = this.fees;
    let feeTotal = fees.reduce((t, fee) => {
      return fee.voided ? t : Big(t).plus(fee.value).toString();
    }, '0');

    return parseFloat(roundedNum(Number(Big(total).plus(feeTotal)), precision).toString());
  }

  // Tip
  @computed get totalCartWithTip() {
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);

    return parseFloat(
      roundedNum(
        Number(Big(this.totalCartWithFees).plus(this.tipAmount)),
        precision
      ).toString()
    );
  }

  //Tax
  @computed get total() {
    const totalWithTip = this.totalCartWithTip;
    const { restaurant } = this.store;
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);

    if (restaurant.settings.region.tax.in_prices) {
      return totalWithTip;
    }

    let taxTotal = this.taxes.reduce((t, tax) => {
      return Big(t).plus(tax.amount).toString();
    }, '0');

    return parseFloat(
      roundedNum(Number(Big(totalWithTip).plus(taxTotal)), precision).toString()
    );
  }

  @computed get isTaxOnTips() {
    const tax = this.store.restaurant.settings.region.tax;
    const { on_tips } = tax;

    // if on_tips is undefined, the default will be true, the tip is taxable
    return on_tips ?? true;
  }

  @computed get tipAmount(): number {
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);

    let tip = this.s.tip;
    if (tip === '') {
      return 0;
    }

    return parseFloat(roundedNum(Number(tip), precision).toString());
  }

  @computed get promo() {
    return this.s.promos.length > 0 ? this.s.promos[0] : 'null';
  }
  @computed get discount() {
    let promo = this.promo;

    if (!promo) return 0;

    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);

    const total = this.totalCart;
    const cartItems = cloneDeepSafe(this.s.items);

    const promoParser = new PromoParser(promo);

    const fixed_discount = promoParser.getFixedDiscount();
    const percent_discount = promoParser.getPercentDiscount();
    const min_order = promoParser.getMinOrder();
    const max_amount = promoParser.getMaxAmount();
    const limit_to_dishes = promoParser.getLimitToDishes();
    const free_dishes = promoParser.getFreeDishes();
    const free_same_only = promoParser.getFreeSameOnly();
    const free_qty = promoParser.getFreeQty();
    const free_required_purchase_qty = promoParser.getFreeRequiredPurchaseQty();
    const promo_type = promoParser.getType();
    const promo_apply_to = promoParser.getApplyTo();

    // 1. If min order not met no discount
    if (min_order && Big(total).lt(min_order)) {
      return 0;
    }

    let discount = Big(0);

    if (free_dishes && isArray(free_dishes) && free_dishes.length > 0) {
      const cartItemsNoDuplicates: Array<{
        _id: string;
        qty: number;
        dishes: T.Schema.Order.OrderDish[];
      }> = [];
      for (const item of cartItems) {
        const index = cartItemsNoDuplicates.findIndex(
          dish => dish._id === item._id
        );
        if (index !== -1) {
          cartItemsNoDuplicates[index].qty += item.qty;
          cartItemsNoDuplicates[index].dishes.push(item);
        } else {
          cartItemsNoDuplicates.push({
            _id: item._id,
            qty: item.qty,
            dishes: [item],
          });
        }
      }

      if (free_same_only) {
        for (const item of cartItemsNoDuplicates) {
          if (free_dishes.indexOf(item._id) !== -1) {
            if (item.qty >= free_required_purchase_qty) {
              const actualFreeQty = freeQtyFromBuyXWithYFree(
                free_required_purchase_qty,
                free_qty,
                item.qty
              );

              const basePrices = [];

              let maxDiscountPrice = '0';

              for (const d of item.dishes) {
                const basePrice = promoFreeQtyBaseCartItemPrice(d);
                for (let i = 0; i < d.qty; i++) {
                  basePrices.push(basePrice);
                }
                if (Big(basePrice).gt(maxDiscountPrice)) {
                  maxDiscountPrice = basePrice;
                }
              }

              basePrices.sort((a, b) => {
                return parseFloat(Big(a).minus(b).toString());
              });

              for (let i = 0; i < actualFreeQty; i++) {
                if (basePrices[i]) {
                  const actualDiscountPrice = Big(basePrices[i]).gt(
                    maxDiscountPrice
                  )
                    ? maxDiscountPrice
                    : basePrices[i];
                  discount = Big(discount).plus(actualDiscountPrice);
                }
              }
            }
          }
        }
      } else {
        let totalFreeItemsQty = 0;
        const cartItemsInFreeList: Array<{
          _id: string;
          qty: number;
          dishes: T.Schema.Order.OrderDish[];
        }> = [];
        for (const item of cartItemsNoDuplicates) {
          if (free_dishes.indexOf(item._id) !== -1) {
            totalFreeItemsQty += item.qty;
            cartItemsInFreeList.push(item);
          }
        }

        if (totalFreeItemsQty >= free_required_purchase_qty) {
          const cartItemsInFreeListByPriceSingleQty: Array<{
            _id: string;
            basePrice: string;
          }> = [];

          for (const item of cartItemsInFreeList) {
            for (const d of item.dishes) {
              const basePrice = promoFreeQtyBaseCartItemPrice(d);
              for (let i = 0; i < d.qty; i++) {
                cartItemsInFreeListByPriceSingleQty.push({
                  _id: d._id,
                  basePrice: basePrice,
                });
              }
            }

            /*
						const menuFindResult = RestaurantUtils.menu.findDish(this.store.restaurant, item._id);
						if (menuFindResult) {
							for (let i = 0; i < item.qty; i++) {
							cartItemsInFreeListByPriceSingleQty.push({
								_id: item._id,
								basePrice: menuFindResult.dish.price,
							});
							}
						}
						*/
          }

          cartItemsInFreeListByPriceSingleQty.sort((a, b) => {
            return parseFloat(Big(a.basePrice).minus(b.basePrice).toString());
            /*
							if (a.basePrice > b.basePrice) return 1;
							else if (a.basePrice < b.basePrice) return -1;
							else return 0;
						*/
          });

          const freeQtyAvailable = freeQtyFromBuyXWithYFree(
            free_required_purchase_qty,
            free_qty,
            totalFreeItemsQty
          );

          for (let i = 0; i < freeQtyAvailable; i++) {
            if (cartItemsInFreeListByPriceSingleQty[i]) {
              discount = Big(discount).plus(
                cartItemsInFreeListByPriceSingleQty[i].basePrice
              );
            }
          }
        }
      }
    }

    if (
      limit_to_dishes &&
      isArray(limit_to_dishes) &&
      limit_to_dishes.length > 0
    ) {
      let active = false;
      for (const dish of cartItems) {
        if (limit_to_dishes.indexOf(dish._id) !== -1) {
          active = true;
          break;
        }
      }
      if (!active) return 0;

      for (const dish of cartItems) {
        if (limit_to_dishes.indexOf(dish._id) !== -1) {
          discount = Big(dish.discount || 0).plus(discount);
        }
      }
    } else {
      const actualPercent = Big(percent_discount || 0).div(100);
      const actualDiscount = Big(total)
        .times(actualPercent)
        .plus(fixed_discount || 0);
      if (promo_type === 'conventional_discount' && 
          promo_apply_to === 'specific_products') {
        for (const dish of cartItems) {
          discount = Big(dish.discount || 0).plus(discount);
        }
      } else {
        discount = Big(discount).plus(actualDiscount);
      }
    }

    // Max Amount
    if (max_amount && max_amount > 0 && Big(discount).gt(max_amount)) {
      discount = Big(max_amount);
    }
    return parseFloat(roundedNum(Number(discount), precision).toString());
  }
  @computed get fees(): T.Schema.Order.OrderServiceFee[] {
    const total = this.totalCartWithDiscount;
    const { store } = this;
    const r = store.restaurant;
    const oc = store.order_config.s;
    const checkout = store.checkout.s;
    const promo = this.promo;
    const promoValid = this.promoValid;

    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);
    if (!oc.confirmed) return [];

    const fees = r.settings?.fees ? r.settings.fees.filter(fee => {
      if (fee.disabled) {
        return false;
      }

      let conditions = 0;
      let matched = 0;
      const matchAny = fee.match_condition === 'any';

      if (fee.payments.length > 0) {
        conditions++;
        // IF THE PAYMENT MUST BE MATCHED, THEN DROP FEE UNTIL A PAYMENT METHOD IS SELECTED
        if (!checkout.payment && !matchAny) {
          return false;
        } else if (
          checkout.payment &&
          fee.payments.indexOf(checkout.payment) !== -1
        ) {
          matched++;
          if (matchAny) {
            return true;
          }
        }
      }

      if (fee.services.length > 0) {
        conditions++;
        // @ts-ignore;
        if (fee.services.indexOf(oc.service) !== -1) {
          matched++;
          if (matchAny) {
            return true;
          }
        }
      }

      if (fee.order_times.length > 0) {
        conditions++;
        // @ts-ignore;
        if (fee.order_times.indexOf(oc.due) !== -1) {
          matched++;
          if (matchAny) {
            return true;
          }
        }
      }

      if (fee.hours.length > 0) {
        conditions++;

        const datetime =
          oc.due === 'now'
            ? store.intl.momentNow()
            : store.intl.momentFromFormat(
                `${oc.date} ${oc.time}`,
                FORMATS.moment.datetime
              );

        const { isWithin } = RestaurantUtils.opening_hours.isTimeWithin({
          dt: datetime,
          timezone: store.intl.s.tz,
          hours: fee.hours,
          first_offset: 0,
          last_offset: 0,
        });

        if (isWithin) {
          matched++;
          if (matchAny) {
            return true;
          }
        }
      }

      // IF CONDITIONS MET = CONDITIONS MATCHED, THEN FEE APPLIES
      return conditions === matched;
    }) : [];

    if (oc.service === 'delivery') {
      const df = r.settings.services.delivery.fee;
      if (oc.zone) {
        const zone = r.settings.services.delivery.zones.find(
          z => z.name === oc.zone
        );
        fees.unshift({
          _id: 'delivery-fee',
          name: store.intl.i18n.t('order.totals.delivery_fee'),
          order_times: [],
          percent_value: 0,
          match_condition: 'any',
          fixed_value: zone ? zone.fee || 0 : 0,
          services: [],
          payments: [],
          hours: [],
          disabled: false,
          tax_exempt: df.tax_exempt || false,
        });
      } else if (df.type === 'formula' && df.formula) {
        try {
          const CT = this.totalCartWithDiscount;
          const DD = oc.distance || 0;
          const DT = oc.driving_time || 0;
          const LI = store.customer.isLoggedIn ? 1 : 0;
          const evaluation = exprEval.Parser.evaluate(df.formula, {
            CT,
            DD,
            DT,
            LI,
          });
          const value = roundedNum(Number(Big(evaluation)), precision).toString();
          console.log(
            `DF VALUES = CT:${CT} | DD:${DD} (OrigD: ${oc.distance} | DT:${DT} | LI:${LI} | KMMILE:${this.store.restaurant.settings.region.kmmile}`
          );
          fees.unshift({
            _id: 'delivery-fee',
            name: store.intl.i18n.t('order.totals.delivery_fee'),
            order_times: [],
            percent_value: 0,
            match_condition: 'any',
            fixed_value: parseFloat(value),
            services: [],
            payments: [],
            hours: [],
            disabled: false,
            tax_exempt: df.tax_exempt || false,
          });
        } catch (e) {}
      } else if (df.type === 'fixed' && df.fixed_cost) {
        console.log('fixed');
        fees.unshift({
          _id: 'delivery-fee',
          name: store.intl.i18n.t('order.totals.delivery_fee'),
          order_times: [],
          percent_value: 0,
          match_condition: 'any',
          fixed_value: df.fixed_cost,
          services: [],
          payments: [],
          hours: [],
          disabled: false,
          tax_exempt: df.tax_exempt || false,
        });
      } else if (
        this.store.isMappedData &&
        df.type === 'range' &&
        df.ranges.length > 0
      ) {
        let fee = df.ranges[0].cost;
        for (const range of df.ranges) {
          const amount =
            this.store.restaurant.settings.region.kmmile === 'KM'
              ? 1000
              : 1609.34;
          if (oc.distance < range.up_to * amount) {
            fee = range.cost;
            break;
          }
        }
        fees.unshift({
          _id: 'delivery-fee',
          name: store.intl.i18n.t('order.totals.delivery_fee'),
          order_times: [],
          percent_value: 0,
          match_condition: 'any',
          fixed_value: fee,
          services: [],
          payments: [],
          hours: [],
          disabled: false,
          tax_exempt: df.tax_exempt || false,
        });
      } else if (oc.delivery_provider === 'postmates') {
        const tax_exempt = r.settings.services.delivery.providers.postmates
          ? r.settings.services.delivery.providers.postmates?.tax_exempt
          : false;
        let fee = (oc.postmates_quote_amount || 0) / 100; // postmates only deals in USD
        fee = fee < 0 ? 0 : fee;
        fees.unshift({
          _id: 'delivery-fee',
          name: store.intl.i18n.t('order.totals.delivery_fee'),
          order_times: [],
          percent_value: 0,
          match_condition: 'any',
          fixed_value: fee || 0,
          services: [],
          payments: [],
          hours: [],
          disabled: false,
          tax_exempt: tax_exempt || false,
        });
      } else if (oc.delivery_provider === 'lalamove') {
        let fee = parseFloat(oc.lalamove_quote_amount || '0') || 0;
        fees.unshift({
          _id: 'delivery-fee',
          name: store.intl.i18n.t('order.totals.delivery_fee'),
          order_times: [],
          percent_value: 0,
          match_condition: 'any',
          fixed_value: fee || 0,
          services: [],
          payments: [],
          hours: [],
          disabled: false,
          tax_exempt: false,
        });
      } else if (oc.delivery_provider === 'uber') {
        let fee = oc.uber_total_fee || 0;
        fees.unshift({
          _id: 'delivery-fee',
          name: store.intl.i18n.t('order.totals.delivery_fee'),
          order_times: [],
          percent_value: 0,
          match_condition: 'any',
          fixed_value: fee || 0,
          services: [],
          payments: [],
          hours: [],
          disabled: false,
          tax_exempt: false,
        });
      }
    }

    return fees.map(fee => {
      const fixed = fee.fixed_value || 0;
      const actualPercent = Big(fee.percent_value || 0).div(100);
      const percent = roundedNum(
        Number(Big(total).times(actualPercent)),
        precision
      ).toString();
      const feeTotal = roundedNum(Number(Big(fixed).plus(percent)), precision).toString();

      let isPaymentFee = false;
      if (
        fee.payments.length > 0 &&
        checkout.payment &&
        fee.payments.indexOf(checkout.payment) !== -1
      ) {
        isPaymentFee = true;
      }

      let voided = false;
      if (fee._id === 'delivery-fee') {
        const df = r.settings.services.delivery.fee;
        voided = df.free_delivery_minimum
          ? Big(this.totalCartWithDiscount).gte(df.free_delivery_minimum)
          : false;

        if (
          df.order_times &&
          df.order_times.length === 1 &&
          df.order_times[0] !== oc.due
        ) {
          voided = true;
        }
        if (!voided && promoValid && promo) {
          if (new PromoParser(promo).getFreeDelivery()) {
            voided = true;
          }
        }
      }

      return {
        _id: fee._id,
        name: fee.name,
        fixed_value: fee.fixed_value,
        percent_value: fee.percent_value,
        value: parseFloat(feeTotal),
        is_payment_fee: isPaymentFee,
        voided: voided,
        tax_exempt: fee.tax_exempt,
      };
    });
  }
  @computed get taxes(): T.Schema.Order.OrderTaxes[] {
    const r = this.store.restaurant;
    const { restaurantSettings } = this.store.ably.s
    const tax = restaurantSettings && restaurantSettings.taxUpdate ? restaurantSettings.taxUpdate : r.settings.region.tax;
    const { rates, in_prices } = tax;
    const taxRates = rates ?? []
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);

    // TAX ON FEES
    const fees = this.fees;

    const feeUntaxableTotal = parseFloat(
      fees.reduce((t, fee) => {
        if (fee.tax_exempt === true) {
          return fee.voided ? t : Big(t).plus(fee.value).toString();
        } else {
          return fee.voided ? t : Big(t).toString();
        }
      }, '0')
    );

    let taxableTotal = this.totalCartWithTip - feeUntaxableTotal;

    // TAX ON TIPS
    if (!this.isTaxOnTips) taxableTotal -= this.tipAmount;

    let compoundedTaxTotal = taxableTotal.toString();

    return taxRates.map((t:T.Core.Business.BusinessTaxDetail, i: number) => {
      const actualPercent = Big(t.rate || 0).div(100);
      const totalUNTaxableDishAmount = this.s.items.reduce(
        (reduceTotal, dish) => {
          if (t.dish_tax.includes(dish._id)) {
            return Big(reduceTotal).toString();
          } else {
            if (dish.discount) {
              return Big(reduceTotal)
                .plus(dish.price)
                .minus(dish.discount)
                .toString();
            } else {
              return Big(reduceTotal).plus(dish.price).toString();
            }
          }
        },
        '0'
      );

      const totalTaxableDishAmount = Big(taxableTotal).minus(
        Big(totalUNTaxableDishAmount)
      );

      let amount;
      if (in_prices) {
        const priceWithoutTax = Big(totalTaxableDishAmount).div(
          Big(actualPercent).plus(1)
        );
        amount = roundedNum(
          Number(Big(totalTaxableDishAmount).minus(priceWithoutTax)),
          precision
        ).toString();
      } else {
        if (!t.compound) {
          amount = roundedNum(
            Number(Big(totalTaxableDishAmount).times(actualPercent)),
            precision
          ).toString();
        } else {
          amount = roundedNum(
            Number(Big(compoundedTaxTotal).times(actualPercent)),
            precision
          ).toString();
        }
      }

      compoundedTaxTotal = roundedNum(
        Number(Big(compoundedTaxTotal).plus(amount)),
        precision
      ).toString();

      return {
        ...t,
        amount: parseFloat(amount),
      };
    });
  }

  @computed get minOrderDelivery(): number | null {
    const r = this.store.restaurant;
    const oc = this.store.order_config.s;
    if (oc.service === 'delivery') {
      if (r.settings.services.delivery.conditions.min_order) {
        return r.settings.services.delivery.conditions.min_order;
      }
    }
    return null;
  }
  @computed get minOrderDeliveryValid() {
    const min = this.minOrderDelivery;
    if (!min) return true;
    return Big(this.totalCart).gte(min);
  }
  @computed get minOrderPromo(): number | null {
    const promo = this.promo;
    if (promo) {
      const minOrder = new PromoParser(promo).getMinOrder();
      if (minOrder) {
        return minOrder;
      }
    }
    return null;
  }
  @computed get minOrderPromoValid() {
    const min = this.minOrderPromo;
    if (min === null) return true;
    return Big(this.totalCart).gte(min);
  }

  @action clear = () => {
    this.s.cartCounter = 0;
    this.s.items = [];
    this.s.promos = [];
    this.s.tip = '';
    this.store.dish.clearPointCollection();
    this.store.order_config.clearDeliveryConfigs();
  };
  @action push = (dish: T.Schema.Order.OrderDish, index?: number) => {
    const items = [...this.s.items];
    if (index !== undefined && index !== -1) {
      // IS EDIT
      items[index] = dish;
    } else {
      const counter = this.s.cartCounter + 1;
      dish.cart_item_id = counter;
      items.push(dish);
      this.update({ cartCounter: counter });
    }
    this.s.items = items;

    const promo: any = this.promo;

    if (promo) {
      const promoParse = new PromoParser(promo);
      const promoType = promoParse.getType();
      if (promoType === 'free_item') {
        this.s.promos = [];
        this.removeFreeItemsFromCart();
      } else if (promoType === 'conventional_discount') {
        this.calculateConventionalDiscount(promo);
      }
      this.promoUpdateDishDiscount();
    }
  };
  @action edit = (index: number) => {
    this.store.cart.promoRemoveWithoutBlacklist();
    const dish = this.s.items[index];
    this.store.dish.setFromCart(toJS(dish), index);
    this.store.modal.toggle('cart');
  };
  @action remove = (index: number) => {
    const promo = this.promo;
    const items = [...this.s.items];
    const removed = items.splice(index, 1);
    this.s.items = items;
    for (const item of removed) {
      this.store.dish.removeDishPointItemForCartItem(item);
    }
    const checkForFreeItem = (promo as T.Schema.Restaurant.Promo.RestaurantPromo)?.free_dishes && !isEmpty((promo as T.Schema.Restaurant.Promo.RestaurantPromo)?.free_dishes) 
    if (promo && (new PromoParser(promo).getType() === 'free_item' || checkForFreeItem)) {
      this.promoRemove();
    }
  };

  @computed get promoValid() {
    if (!this.promo) {
      return false;
    }
    // Min order and limit to dishes are not checked before promo is applied, so need to recheck it here
    return this.promoLimitToDishesValid && this.minOrderPromoValid;
  }
  @computed get promoLimitToDishesValid() {
    const promo = this.promo;
    if (!promo) {
      return false;
    }

    const promoParser = new PromoParser(promo);
    const limit_to_dishes = promoParser.getLimitToDishes();
    let valid = false;

    if (!limit_to_dishes || limit_to_dishes.length === 0) {
      return true;
    }

    if (isArray(limit_to_dishes)) {
      const items = this.s.items;
      for (const dish of items) {
        if (limit_to_dishes.indexOf(dish._id) !== -1) {
          valid = true;
          break;
        }
      }
    }
    return valid;
  }

  @action promoCodeApply = async () => {

    // console.log("promoCodeApply")

    try {
      const formCode = this.getFormPromo().code;
      let promoCode = null
      this.s.isApplyingPromoCode = true
      if (formCode && formCode.length > 0) {
        const matchPublicCode = this.getPublicPromoCode(formCode)
        if (matchPublicCode) {
          promoCode = matchPublicCode
        } else {
          const matchPrivateCode = await this.getPrivatePromoCode(formCode)
          if (matchPrivateCode) {
            promoCode = matchPrivateCode
          }
        }
        if (!promoCode) {
          this.updateFormPromo({ error: 'not_found' });
          this.s.isApplyingPromoCode = false;
          return
        } else {
          this.validatePromoCode(promoCode)
          this.s.isApplyingPromoCode = false;
        }
      }else{
        promoCode = this.getAutoApplyPromoCode()
        if(!promoCode){
          this.s.isApplyingPromoCode = false;
          return 
        }else{
          this.validatePromoCode(promoCode,true)
          this.updateFormPromo({ error: '' });
          this.s.isApplyingPromoCode = false;
        }
      }

    } catch (error) {
      // console.log(error)
      logger.captureException(error);
      this.updateFormPromo({ error: 'generic' });
      this.s.isApplyingPromoCode = false;
    }
  }

  @action getPublicPromoCode = (formCode: string) => {
    const restaurant = this.store.restaurant;
    const promoList: any[] = [
      ...restaurant.promos,
      ...(restaurant.free_item_promos || []),
      ...(restaurant.conventional_discount_promos || []),
    ];

    const index = promoList.findIndex(
      p => p.code.toLowerCase() === formCode.toLowerCase()
    );

    if (index === -1) {
      return null
    } else {
      return promoList[index]
    }
  }

  @action getPrivatePromoCode = async (formCode: string) => {
    const restaurantID = this.store.restaurant._id;
    const promoResponse = await this.store.api.getStorePromoCode({
      restaurantID,
      promoCode: formCode
    });
    if (promoResponse.outcome) {
      return null
    } else {
      return promoResponse.promo
    }
  }

  @action getAutoApplyPromoCode = () => {
    const restaurant = this.store.restaurant;
    const promoList: any[] = [
      ...restaurant.promos,
      ...(restaurant.free_item_promos || []),
      ...(restaurant.conventional_discount_promos || []),
    ];
    const promoCodeBlacklist = this.s.promoCodeBlacklist;

    for (const promo of promoList) {
      if ((promo.disabled !== true) &&
        (promo.auto_apply || promo.restrictions?.automatically_apply) &&
        !promoCodeBlacklist?.includes(promo.code) &&
        this.isPromoValid(promo)) {
        return promo
      }
    }
    return null
  }

  @action validatePromoCode = (promo: any, hideError: boolean = false) => {
    // check if promo is already applied
    const isPromoAlreadyApply = promo ? this.s.promos.some(p => p.code === promo.code) : false;
    if (isPromoAlreadyApply) return

    if ((promo as FreeItem).type === 'free_item') {
      this.validateFreeItemPromo(promo, hideError);
    } else if ((promo as ConventionalDiscountPromo).type === 'conventional_discount') {
      this.validateConventionalPromo(promo, hideError);
    } else {
      this.validatePromo(promo, hideError);
    }
  }

  @action promoAdd = (promoCode?: string, hideError: boolean = false) => {
    console.log("go too promoAdd");
    console.log("promoCode", promoCode);
    if (hideError) this.updateFormPromo({ error: '' });
    const code = promoCode || this.form.promo.code;
    if (!code) {
      this.updateFormPromo({ error: '' });
      this.autoApplyPromo();
      this.store.modal.show('checkout');
      return;
    }

    try {
      // this.store.cart.updateFormPromo({ error: '', code});
      const r = this.store.restaurant;
      const promoList: any[] = [
        ...r.promos,
        ...(r.free_item_promos || []),
        ...(r.conventional_discount_promos || []),
      ];
      const index = promoList.findIndex(
        p => p.code.toLowerCase() === code.toLowerCase()
      );

      if (index === -1 && !hideError) {
        this.updateFormPromo({ error: 'not_found' });
        return;
      }
      const promo = promoList[index];
      if ((promo as FreeItem).type === 'free_item') {
        this.validateFreeItemPromo(promo, hideError);
      } else if ((promo as ConventionalDiscountPromo).type === 'conventional_discount') {
        this.validateConventionalPromo(promo, hideError);
      } else {
        this.validatePromo(promo, hideError);
      }
    } catch (e) {
      logger.captureException(e);
      if (!hideError) this.updateFormPromo({ error: 'generic' });
    }
  };

  @action validateFreeItemPromo = (promo: FreeItem, hideError: boolean) => {
    const now = Date.now();
    const oc = this.store.order_config.s;
    const c = this.store.customer.s.item;
    let cartItems = this.store.cart.s.items;
    const { restaurant } = this.store;
    let itemQty = cartItems.reduce((acc, item) => acc + item.qty, 0);
    const { restrictions, condition } = promo;

    if (restrictions.authenticated_user_only && !c) {
      if (!hideError) this.updateFormPromo({ error: 'logged_in_only' });
      return;
    }

    if (promo.disabled) {
      if (!hideError) this.updateFormPromo({ error: 'not_available' });
      return;
    }
    
    if (restrictions.once_per_customer && typeof window !== 'undefined') {
      const oncePerCustomerInvalid = c
        ? c.stats.promos_used.indexOf(promo._id) !== -1
        : !!localStorage.getItem(promo._id);

      if (oncePerCustomerInvalid) {
        if (!hideError) this.updateFormPromo({ error: 'already_used' });
        return;
      }
    }

    if (restrictions.service_type && restrictions.service_type.length > 0) {
      if (
        restrictions.service_type.indexOf(
          oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
        ) === -1
      ) {
        if (!hideError) this.updateFormPromo({ error: 'invalid_service' });
        return;
      }
    }

    if (restrictions.order_times && restrictions.order_times.length > 0) {
      if (
        restrictions.order_times.indexOf(
          oc.due as T.Core.Business.BusinessOrderingTimes
        ) === -1
      ) {
        if (!hideError) {
          if (oc.due === 'now') {
            this.updateFormPromo({ error: 'later_only' });
          } else {
            this.updateFormPromo({ error: 'now_only' });
          }
        }
        return;
      }
    }

    if (
      restrictions.available_date_range &&
      restrictions.available_date_range.length > 0
    ) {
      for (const time of restrictions.available_date_range) {
        const valid = now >= time.start && now <= time.end;
        if (!valid) {
          if (!hideError) this.updateFormPromo({ error: 'not_available' });
          return;
        }
      }
    }

    if (restrictions.available_times) {
      const isAvailableTime = RestaurantUtils.opening_hours.isTimeWithin({
        dt: moment.tz(Date.now(), restaurant.settings.region.timezone),
        hours: restrictions.available_times,
        last_offset: 0,
        first_offset: 0,
        timezone: restaurant.settings.region.timezone,
      }).isWithin;

      if (!isAvailableTime) {
        this.updateFormPromo({ error: 'not_available_time' });
        return;
      }
    }

    let conditionError = false;
    const totalAmount = this.store.cart.total;
    // console.log({ totalAmount });
    let isWithinTier = false;
    switch (condition.type) {
      case 'item_quantity':
        condition.tiers.forEach(item => {
          if (
            // @ts-ignore
            itemQty >= Number(item.lower_limit) &&
            itemQty <= Number(item.upper_limit)
          ) {
            isWithinTier = true;
          }
        });
        if (!isWithinTier) {
          this.updateFormPromo({ error: 'not_eligible' });
          conditionError = true;
          return;
        }
        break;
      case 'order_amount':
        condition.tiers.forEach(item => {
          if (
            // @ts-ignore
            totalAmount >= item.lower_limit &&
            totalAmount <= item.upper_limit
          ) {
            isWithinTier = true;
          }
        });
        if (!isWithinTier) {
          this.updateFormPromo({ error: 'not_eligible' });
          conditionError = true;
          return;
        }

        break;
      default:
        break;
    }
    if(condition.type === 'item_quantity'){
      const items = [];
      for (let key in condition.eligible_items) {
        let isInclude = false;
        const obj = condition.eligible_items[key];
        if (!obj.menu.length && !obj.category.length && !obj.dish.length) {
          continue;
        }
        for (let i = 0; i < cartItems.length; i++) {
          if (
            obj.menu.includes(cartItems[i].menu_id) || obj.category.includes(cartItems[i].category_id) || obj.dish.includes(cartItems[i]._id)
          ) {
            isInclude = true;
            items.length = 0;
            break;
          }
          items.push(cartItems[i]._id);
        }

        if (isInclude) break;
      }
    
      if (items.length > 0) {
        this.updateFormPromo({ error: 'not_eligible' });
        conditionError = true;
        return;
      }
    }
    

    if (condition.deal) {
      this.applyWalletlyDeal(promo);
      return;
    }

    if (conditionError) return;
    this.updateFormPromo({ error: '', code: '' });
    // @ts-ignore
    this.s.promos.push(promo); // TODO: revise type def
    return;
  };

  validateConventionalPromo = (
    promo: ConventionalDiscountPromo,
    hideError: boolean
  ) => {
    const now = Date.now();
    const oc = this.store.order_config.s;
    const c = this.store.customer.s.item;
    let cartItems = this.store.cart.s.items;
    const { restaurant } = this.store;
    const { restrictions, condition } = promo;
    const walletly = this.store.restaurant.settings.loyalty_providers?.walletly;


    if (promo.disabled) {
      if (!hideError) this.updateFormPromo({ error: 'not_available' });
      return;
    }

    if (promo.restrictions && promo.restrictions.authenticated_user_only && !c) {
      if (!hideError) this.updateFormPromo({ error: 'logged_in_only' });
      return;
    }

    if (restrictions.once_per_customer && typeof window !== 'undefined') {
      const oncePerCustomerInvalid = c
        ? c.stats.promos_used.indexOf(promo._id) !== -1
        : !!localStorage.getItem(promo._id);
      if (oncePerCustomerInvalid) {
        if (!hideError) this.updateFormPromo({ error: 'already_used' });
        return;
      }
    }

    if (restrictions.service_type && restrictions.service_type.length > 0) {
      if (
        restrictions.service_type.indexOf(
          oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
        ) === -1
      ) {
        if (!hideError) this.updateFormPromo({ error: 'invalid_service' });
        return;
      }
    }

    if (restrictions.order_times && restrictions.order_times.length > 0) {
      if (
        restrictions.order_times.indexOf(
          oc.due as T.Core.Business.BusinessOrderingTimes
        ) === -1
      ) {
        if (!hideError) {
          if (oc.due === 'now') {
            this.updateFormPromo({ error: 'later_only' });
          } else {
            this.updateFormPromo({ error: 'now_only' });
          }
        }
        return;
      }
    }

    if (
      restrictions.available_date_range &&
      restrictions.available_date_range.length > 0
    ) {
      for (const time of restrictions.available_date_range) {
        const valid = now >= time.start && now <= time.end;
        if (!valid) {
          if (!hideError) this.updateFormPromo({ error: 'not_available' });
          return;
        }
      }
    }

    if (restrictions.available_times) {
      const isAvailableTime = RestaurantUtils.opening_hours.isTimeWithin({
        dt: moment.tz(Date.now(), restaurant.settings.region.timezone),
        hours: restrictions.available_times,
        last_offset: 0,
        first_offset: 0,
        timezone: restaurant.settings.region.timezone,
      }).isWithin;

      if (!isAvailableTime) {
        this.updateFormPromo({ error: 'not_available_time' });
        return;
      }
    }
    let conditionError = false;
    const items = [];
    for (let key in condition.eligible_items) {
      let isInclude = false;
      const obj = condition.eligible_items[key];
      if (!obj.menu.length && !obj.category.length && !obj.dish.length) {
        continue;
      }
      for (let i = 0; i < cartItems.length; i++) {
        if (
          obj.menu.includes(cartItems[i].menu_id) ||
          obj.category.includes(cartItems[i].category_id) ||
          obj.dish.includes(cartItems[i]._id)
        ) {
          isInclude = true;
          items.length = 0;
          break;
        }
        items.push(cartItems[i]._id);
      }

      if (isInclude) break;
    }

    if (items.length > 0) {
      this.updateFormPromo({ error: 'not_eligible' });
      conditionError = true;
      return;
    }

    if (conditionError) return false;
    if (!this.calculateConventionalDiscount(promo)) return false;
    if (condition.deal) this.applyWalletlyDeal(promo);

    this.updateFormPromo({ error: '', code: '' });
    this.s.promos.push(promo);
    return true;
  };

  // TODO: OMG refactor this
  // This calculates line item discounts only. Total discount is calculated at @computed discount()
  @action calculateConventionalDiscount(promo: ConventionalDiscountPromo) {

    if (promo && promo.condition.apply_to == "order_amount") return true;
    let conditionError = false;
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);
    let cartItems = this.store.cart.s.items;
    const { condition } = promo;
    const _items = [];
    const itemIncludes = [];
    const applyMultiple = condition.apply_multiple === undefined || condition.apply_multiple === true;
    let selectedItemIndex = 0;

    // Apply to one line item only
    if (!applyMultiple) {

      // Find highest priced item
      let highestPrice = 0;
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].price > highestPrice) {
          highestPrice = cartItems[i].price;
          selectedItemIndex = i;
        }
      }

      // Apply discount
      if (promo.condition.type === 'percentage') {
        this.updateCartItem(selectedItemIndex, {
          discount: (cartItems[selectedItemIndex].price / 100 * promo.condition.discount_value)
        });
      } else if (promo.condition.type === 'fixed_amount') {
        this.updateCartItem(selectedItemIndex, {
          discount: promo.condition.discount_value
        })
      }
    } else {

      // Apply to multiple items is true and no eligible items are selected.
      // Discount will be applied to all line items.
      if (Object.keys(condition.eligible_items).length == 0) {
        for (let i = 0; i < cartItems.length; i++) {
          if (promo.condition.type === 'percentage') {
            this.updateCartItem(i, {
              discount: (cartItems[i].price / 100 * promo.condition.discount_value)
            });
          } else if (promo.condition.type === 'fixed_amount') {
            this.updateCartItem(i, {
              discount: promo.condition.discount_value
            })
          }
        }
      } else {
        
        // There are selected eligible items and discounts will only be applied to those.
        for (let key in condition.eligible_items) {
          let isInclude = false;
          const obj = condition.eligible_items[key];
          if (!obj.menu.length && !obj.category.length && !obj.dish.length) {
            for (let i = 0; i < cartItems.length; i++) {
              if (promo.condition.type === 'percentage') {
                this.updateCartItem(i, {
                  discount: (cartItems[i].price / 100 * promo.condition.discount_value)
                });
              } else if (promo.condition.type === 'fixed_amount') {
                this.updateCartItem(i, {
                  discount: promo.condition.discount_value
                })
              }
            }
            continue;
          }
          
          for (let i = 0; i < cartItems.length; i++) {
            if (
              obj.menu.includes(cartItems[i].menu_id) ||
              obj.category.includes(cartItems[i].category_id) ||
              obj.dish.includes(cartItems[i]._id)
            ) {
              isInclude = true;
              _items.length = 0;
              itemIncludes.push(cartItems[i]);
              continue;
            }
            _items.push(cartItems[i]._id);
          }

          if (isInclude) {
            for (const item of itemIncludes) {
              for (let i = 0; i < cartItems.length; i++) {
                if (item._id === cartItems[i]._id) {
                  if (promo.condition.type === 'percentage') {
                    this.updateCartItem(i, {
                      discount: (cartItems[i].price / 100 * promo.condition.discount_value)
                    });
                  } else if (promo.condition.type === 'fixed_amount') {
                    this.updateCartItem(i, {
                      discount: promo.condition.discount_value
                    })
                  }
                }
              }
            }
            break;
          }

          if (!itemIncludes.length && _items.length > 0) {
            this.updateFormPromo({ error: 'not_eligible' });
            conditionError = true;
            return false;
          }

          if (conditionError) return false;
        }
      }
    }

    return true;
  }

  validatePromo = (promo: Promo, hideError: boolean) => {
    const now = Date.now();
    const oc = this.store.order_config.s;
    const c = this.store.customer.s.item;

    if (promo.disabled) {
      if (!hideError) this.updateFormPromo({ error: 'disabled' });
      return;
    }

    if (promo.logged_in_only && !c) {
      if (!hideError) this.updateFormPromo({ error: 'logged_in_only' });
      return;
    }

    if (promo.once_per_customer && typeof window !== 'undefined') {
      const oncePerCustomerInvalid = c
        ? c.stats.promos_used.indexOf(promo._id) !== -1
        : !!localStorage.getItem(promo._id);

      if (oncePerCustomerInvalid) {
        if (!hideError) this.updateFormPromo({ error: 'already_used' });
        return;
      }
    }

    if (!!promo.max_uses && promo.stats.used > promo.max_uses) {
      if (!hideError) this.updateFormPromo({ error: 'invalid' });
      return;
    }

    if (promo.services && promo.services.length > 0) {
      if (
        promo.services.indexOf(
          oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
        ) === -1
      ) {
        if (!hideError) this.updateFormPromo({ error: 'invalid_service' });
        return;
      }
    }

    if (promo.times && promo.times.length > 0) {
      if (
        promo.times.indexOf(oc.due as T.Core.Business.BusinessOrderingTimes) ===
        -1
      ) {
        if (!hideError) {
          if (oc.due === 'now') {
            this.updateFormPromo({ error: 'later_only' });
          } else {
            this.updateFormPromo({ error: 'now_only' });
          }
        }
        return;
      }
    }

    if (promo.valid_times.length > 0) {
      for (const time of promo.valid_times) {
        const valid = now >= time.start && now <= time.end;
        if (!valid) {
          if (!hideError) this.updateFormPromo({ error: 'not_available' });
          return;
        }
      }
    }

    this.updateFormPromo({ error: '', code: '' });
    this.s.promos.push(promo);
    this.promoUpdateDishDiscount();
    return;
  };

  validateAutoApplyPromo = (promo: Promo): boolean => {
    const now = Date.now();
    const oc = this.store.order_config.s;
    const c = this.store.customer.s.item;

    if (promo.disabled) {
      return false;
    }

    if (promo.logged_in_only && !c) {
      return false;
    }

    if (promo.once_per_customer && typeof window !== 'undefined') {
      const oncePerCustomerInvalid = c
        ? c.stats.promos_used.indexOf(promo._id) !== -1
        : !!localStorage.getItem(promo._id);

      if (oncePerCustomerInvalid) {
        return false;
      }
    }

    if (!!promo.max_uses && promo.stats.used > promo.max_uses) {
      return false;
    }

    if (promo.services && promo.services.length > 0) {
      if (
        promo.services.indexOf(
          oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
        ) === -1
      ) {
        return false;
      }
    }

    if (promo.times && promo.times.length > 0) {
      if (
        promo.times.indexOf(oc.due as T.Core.Business.BusinessOrderingTimes) ===
        -1
      ) {
        return false;
      }
    }

    if (promo.valid_times.length > 0) {
      for (const time of promo.valid_times) {
        const valid = now >= time.start && now <= time.end;
        if (!valid) {
          return false;
        }
      }
    }

    return true;
  };

  @action autoApplyPromo = () => {
    const restaurant = this.store.restaurant;
    // const promos = restaurant.promos;
    const promoList: any[] = [
      ...restaurant.promos,
      ...(restaurant.free_item_promos || []),
      ...(restaurant.conventional_discount_promos || []),
    ];
    const promoCodeBlacklist = this.s.promoCodeBlacklist;
    
    for (const promo of promoList) {
      if (this.s.promos.length > 0) {
        // console.log("There's already a promo added to cart.");
        break;
      }
      // console.log(`Promo Name: ${promo.code}; Auto Apply: ${promo.auto_apply}; Blacklisted: ${promoCodeBlacklist?.includes(promo.code)}; Promo Valid: ${this.isPromoValid(promo)}`);
      if ((promo.disabled !== true) && 
          (promo.auto_apply || promo.restrictions?.automatically_apply) && 
          !promoCodeBlacklist?.includes(promo.code) &&
          this.isPromoValid(promo)) {
        // console.log(`Adding promo: ${promo.code}`);
        this.promoAdd(promo.code, true);
        break;
      }
    }
  };

  isPromoValid = (promo: any): boolean => {
    if ((promo as FreeItem).type === 'free_item') {
        // TODO: implement validation for auto applying free item promos
        return true; //for now
      } else if ((promo as ConventionalDiscountPromo).type === 'conventional_discount') {
        // TODO: implement validation for auto applying conventional discount promos
        return true; //for now
      } else {
        return this.validateAutoApplyPromo(promo);
      }
  }

  @action promoCodeBlacklistAdd = (code: string) => {
    const promoCodeBlacklist = this.s.promoCodeBlacklist;

    if (!promoCodeBlacklist?.includes(code)) promoCodeBlacklist.push(code);
  };

  @action promoRemove = () => {
    // Not allow auto-applies the promo code that user has already removed from cart
    if (this.s.promos && this.s.promos.length > 0)
      this.promoCodeBlacklistAdd(this.s.promos[0].code);
    this.s.promos = [];
    this.removeFreeItemsFromCart();
    this.promoUpdateDishDiscount();
    const items = this.s.items;
    for (const [index] of items.entries()) {
      this.updateCartItem(index, { discount: undefined });
    }
  };

  @action promoRemoveWithoutBlacklist = () => {
    this.s.promos = [];
    this.removeFreeItemsFromCart();
    this.promoUpdateDishDiscount();
    const items = this.s.items;
    for (const [index] of items.entries()) {
      this.updateCartItem(index, { discount: undefined });
    }
  };

  @action promoUpdateDishDiscount = () => {
    let { precision } = this.store.intl.s.currency;
    precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision);
    const items = this.s.items;
    const promo = this.promo;

    if (!promo || !this.minOrderPromoValid) {
      for (const [index] of items.entries()) {
        this.updateCartItem(index, { discount: undefined });
      }
    }

    if (promo && this.minOrderPromoValid) {
      const promoParser = new PromoParser(promo);
      const limit_to_dishes = promoParser.getLimitToDishes();
      const percent_discount = promoParser.getPercentDiscount();
      const fixed_discount = promoParser.getFixedDiscount();
      const max_amount = promoParser.getMaxAmount();

      if (limit_to_dishes && limit_to_dishes.length > 0) {
        Big.RM = 0;
        // Apply the original discounts
        let totalDiscounts = 0;
        for (const [index, dish] of items.entries()) {
          if (
            isArray(limit_to_dishes) &&
            limit_to_dishes.indexOf(dish._id) !== -1
          ) {
            let discount = Big(0);

            if (percent_discount) {
              const actualPercent = Big(percent_discount || 0).div(100);
              // console.log(`Big.DP = ${Big.DP}`);
              // console.log(`promo.percent_discount = ${promo.percent_discount}; promo actualPercent = ${actualPercent}, precision = ${precision}`);
              discount = Big(dish.price).times(actualPercent);
            }

            if (fixed_discount) {
              discount = discount.plus(fixed_discount);
            }

            if (discount.gt(0)) {
              totalDiscounts += parseFloat(
                roundedNum(Number(discount), precision).toString()
              );
              this.updateCartItem(index, {
                discount: parseFloat(roundedNum(Number(discount), precision).toString()),
              });
            }
          }
        }

        if (max_amount && max_amount > 0 && totalDiscounts > max_amount) {
          // Divide up the promo to ensure the max amount is respected
          let ratio = max_amount / totalDiscounts;
          for (const [index, dish] of items.entries()) {
            if (
              isArray(limit_to_dishes) &&
              limit_to_dishes.indexOf(dish._id) !== -1
            ) {
              if (dish.discount) {
                let newDiscount = parseFloat(
                  roundedNum(Number(dish.discount * ratio), precision).toString()
                );
                this.updateCartItem(index, {
                  discount: newDiscount,
                });
              }
            }
          }
        }
      }
    }
  };

  @action updateFormPromo = (data: Partial<PromoForm>) => {
    if (data.code === '') data.error = '';

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof PromoForm];
        if (value !== undefined) {
          this.form.promo[key as keyof PromoForm] = value;
        }
      }
    }
  };

  @action getFormPromo = () => {
    return this.form.promo;
  };

  @action update = (data: Partial<CartState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof CartState];
        if (value !== undefined) {
          // @ts-ignore
          this.s[key as keyof CartState] = value;
        }
      }
    }
  };

  @action updateCartItem = (
    index: number,
    data: Partial<T.Schema.Order.OrderDish>
  ) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Schema.Order.OrderDish];
        if (value !== undefined) {
          // @ts-ignore
          this.s.items[index][key as keyof T.Schema.Order.OrderDish] = value;
        } else {
          delete this.s.items[index][key as keyof T.Schema.Order.OrderDish];
        }
      }
    }
  };

  @action addFreeItemsToCart = (freeItems: T.Schema.Order.OrderDish[]) => {
    const currentItems = this.s.items;
    const newItems = [...currentItems, ...freeItems];
    this.s.items = newItems;
  };
  @action removeFreeItemsFromCart = () => {
    const currentItems = this.s.items;
    const newItems = currentItems.filter(item => !item.isFreeItems);
    localStorage.removeItem('isSelectedFreeItem');
    this.s.items = newItems;
  };

  @action conditionTierFilter(
    promo: any,
    cartItemQtyOrTotal: any,
    sortBy: string
  ) {
    let freeQty: number = 0;
    if (promo) {
      if (promo?.condition?.tiers) {
        const tierList = promo.condition.tiers.filter(
          (item: ConditionTiers) =>
            cartItemQtyOrTotal >= item.lower_limit &&
            cartItemQtyOrTotal <= item.upper_limit
        );
        let promoSorted;
        let matchedTier;
        switch (sortBy) {
          case 'free_quantity':
            promoSorted = _sortBy(tierList, [
              tier => parseInt(tier.free_quantity),
            ]);
            matchedTier =
              promoSorted.length > 0
                ? promoSorted[promoSorted.length - 1]
                : null;
            freeQty =
              matchedTier && matchedTier.free_quantity
                ? matchedTier.free_quantity
                : 0;
            break;
          case 'upper_limit':
            promoSorted = _sortBy(tierList, [
              tier => parseInt(tier.upper_limit),
            ]);
            break;
          default:
            break;
        }

        return {
          tiers: promo.condition.tiers,
          matchedTier: matchedTier,
          freeQty: freeQty,
        };
      }
    }
    return {
      tiers: null,
      matchedTier: null,
      freeQty: freeQty,
    };
  }

  @action applyWalletlyDeal = async (promo: NewGenericPromo) => {
    const dealId = promo.condition.deal;
    const apiKey =
      this.store.restaurant.settings.loyalty_providers!.walletly!.api_key!;
    if (dealId) {
      const promoList: any[] = [
        ...(this.store.restaurant.free_item_promos || []),
        ...(this.store.restaurant.conventional_discount_promos || []),
      ];

      const isCartModal = this.store.modal.isVisible('cart');
      const walletly =
        this.store.restaurant.settings.loyalty_providers?.walletly;

      if (!walletly || !walletly.enabled) {
        this.updateFormPromo({ error: 'not_available' });
        return;
      }

      const found = promoList.find(
        (promo: any) => promo.condition.deal === dealId
      );
      const email = this.store.customer.s.item?.details.email!;
      const brandId = walletly.brand_id;
      if (!found) {
        this.updateFormPromo({ error: 'not_available' });
        return;
      }

      const flowsCW: any = toJS(
        this.store.customer.s.item?.loyalty?.walletly_deals
      );
      if (!flowsCW) {
        this.updateFormPromo({ error: 'not_available' });
        return;
      }

      const matchedDeal = flowsCW.find((deal: any) => deal.id === dealId);
      if (!matchedDeal && !matchedDeal.valid) {
        this.updateFormPromo({ error: 'not_available' });
        return;
      }

      const payload = {
        email,
        brandId,
        apiKey,
        deal: {
          condition: matchedDeal.condition,
          conditionValue: matchedDeal.conditionValue,
          subtract: matchedDeal.subtract,
          id: matchedDeal.id,
        },
      };

      const validationDeal = await this.store.api.validationDeal(payload);

      if (validationDeal.outcome) {
        this.updateFormPromo({ error: 'not_available' });
        return;
      }
    }
    this.updateFormPromo({ error: '', code: '' });
    this.s.promos.push(promo);
    return;
  };
}
