import { action, autorun, computed, observable, runInAction, toJS } from "mobx";
import { FiltersState, FiltersStore } from "./state/filters";
import { ViewState, ViewStore } from "./state/view";
import {
	CustomerState,
	CustomerStore,
	CustomerFormState,
} from "./state/customer";
import { ModalState, ModalStore } from "./state/modal";
import { OrderConfigState, OrderConfigStore } from "./state/order-config";
import { DishStore, DishState } from "./state/dish";
import { CartState, CartStore } from "./state/cart";
import { CheckoutState, CheckoutStore } from "./state/checkout";
import { APIStore } from "./api";
import stringify from "fast-safe-stringify";
import autobind from "autobind-decorator";
import { RestaurantUtils, FORMATS, CoreUtils, logger } from "@lib/common";
import { OrderState, OrderStore } from "./state/order";
import { SessionStorage } from "../libs/session-storage";
import { LoaderState, LoaderStore } from "./state/loader";
import { IntlStore } from "@lib/intl";
import { RouterStore, RouterState } from "@lib/router";
import { BookingState, BookingStore } from "./state/booking";
import { OrderHistoryState, OrderHistoryStore } from "./state/order-history";
import { cloneDeepSafe } from "./utils";
import { config } from "../../config";
import { GoogleServiceLib } from "@lib/common";
import _omit from "lodash/omit";
import { MenuSearchState, MenuSearchStore } from "./state/search";
import { API } from "../../server/api";
import { AblyState, AblyStore } from "./ably";

interface InitialStates {
	reseller: T.Schema.Reseller.ResellerSchema;
	restaurant: T.Schema.Restaurant.RestaurantSafeSchema;
	restaurantStock: T.Schema.RestaurantMenuStock.Schema;
	billing: T.Schema.Billing.BillingSchema | null;
	customer?: CustomerState;
	customerForm?: CustomerFormState;
	order_config?: OrderConfigState;
	order_history?: OrderHistoryState;
	filters?: FiltersState;
	dish?: DishState;
	cart?: CartState;
	checkout?: CheckoutState;
	view?: ViewState;
	modal?: ModalState;
	order?: OrderState;
	booking?: BookingState;
	loader?: LoaderState;
	router?: RouterState;
	checkoutPayment?: any;
	menuSearch?: MenuSearchState;
	ably?: AblyState;
}

type AvailabilityCheck = {
	available: boolean;
	reason: string;
};

@autobind
export class RootStore {
	api: APIStore;

	// STATIC
	reseller: T.Schema.Reseller.ResellerSchema;
	restaurant: T.Schema.Restaurant.RestaurantSafeSchema;
	billing: T.Schema.Billing.BillingSchema | null;

	// DYNAMIC
	@observable restaurantStock: T.Schema.RestaurantMenuStock.Schema;
	intl: IntlStore;
	customer: CustomerStore;
	order_config: OrderConfigStore;
	order_history: OrderHistoryStore;
	filters: FiltersStore;
	dish: DishStore;
	cart: CartStore;
	checkout: CheckoutStore;
	view: ViewStore;
	modal: ModalStore;
	order: OrderStore;
	booking: BookingStore;
	loader: LoaderStore;
	router: RouterStore;
	checkoutPayment: boolean;
	menuSearch: MenuSearchStore;
	ably: AblyStore;

	// simply pass a parsed serialized state, the reason it's not auto parsed is to enable type safe construction if needed
	constructor(initialStates: InitialStates) {
		this.reseller = initialStates.reseller;
		this.restaurant = initialStates.restaurant;
		this.billing = initialStates.billing;
		this.restaurantStock = initialStates.restaurantStock;
		this.checkoutPayment = false;

		const r = this.restaurant;
		if (this.billing) {
			this.billing.stripe_connect.restaurants = (this.billing?.stripe_connect.restaurants || []).filter((resto) => resto.restaurant_id === this.restaurant._id)
		}

		this.intl = new IntlStore({
			useReactModule: true,
			initialState: {
				tz: r.settings.region.timezone,
				locale: r.settings.region.locale,
				formats: r.settings.region.formats,
				lng: r.settings.region.locale.split("-")[0],
				currency: {
					...r.settings.region.currency,
					step: CoreUtils.currency.precision_to_step(
						r.settings.region.currency.precision
					),
				},
			},
		});
		this.api = new APIStore(this);

		this.router = new RouterStore(initialStates.router);
		this.order_config = new OrderConfigStore(this, initialStates.order_config);
		this.order_history = new OrderHistoryStore(
			this,
			initialStates.order_history
		);
		this.filters = new FiltersStore(this, initialStates.filters);
		this.customer = new CustomerStore(
			this,
			initialStates.customer,
			initialStates.customerForm
		);
		this.cart = new CartStore(this, initialStates.cart);
		this.checkout = new CheckoutStore(this, initialStates.checkout);
		this.dish = new DishStore(this, initialStates.dish);
		this.view = new ViewStore(this, initialStates.view);
		this.modal = new ModalStore(this, initialStates.modal);
		this.order = new OrderStore(this, initialStates.order);
		this.booking = new BookingStore(this, initialStates.booking);
		this.loader = new LoaderStore(this, initialStates.loader);
		this.menuSearch = new MenuSearchStore(this, initialStates.menuSearch);
		this.ably = new AblyStore(this,  initialStates.ably)
	}

	restoreSavedSessions = async () => {
		if (typeof window !== "undefined") {
			if (this.router.s.query.callback_order) {
				await this.checkout.order_callback();
			} else {
				// ON BACK BUTTON RESTORE PESAPAL SESSION
				let restored = !!this.sessionRestore("pesapal");
				if (!restored) {
					restored = !!this.sessionRestore("bambora_apac");
				}
				if (!restored) {
					this.sessionRestore("cart-session", 1000 * 60 * 120); // Within the last 10 minutes
				}
				this.checkout.update({ loading: false });
			}
			autorun(() => {
				if (this.modal.s.active !== "reseller-portal-back") {
					this.sessionSave("cart-session", this.getCompleteSessionNoView());
				}
				// this.printSessionList();
			});
		}
	};

	@computed get termsActive() {
		return !!this.restaurant.settings.terms;
	}

	@computed get mapType() {
		const r = this.restaurant;
		return r.location.map_data.type;
	}

	@computed get isMappedData() {
		const r = this.restaurant;
		const type = r.location.map_data.type;
		return type === "google_maps" || type === "osm";
	}

	@computed get googleMapsClient() {

		if (typeof window === "undefined" || this.mapType !== "google_maps") {
			return null;
		}

		let GoogleService;

		if (this.reseller
			&& this.reseller.billing
			&& this.reseller.billing.reseller_billing_google_api_key
			&& this.reseller.billing.reseller_billing_google_api_key.length > 0
		) {
			try {
				GoogleService = GoogleServiceLib(this.reseller.billing.reseller_billing_google_api_key)
			} catch (e) {
				GoogleService = null
			}
		} else {
			try {
				GoogleService = GoogleServiceLib(config.google_api_key)
			} catch (e) {

				GoogleService = null
			}

		}
		return GoogleService
	}

	@computed get restaurantServices() {
		const r = this.restaurant;
		const services = cloneDeepSafe(r.settings.services);
		for (const k in services) {
			if (services.hasOwnProperty(k)) {
				const key = k as keyof typeof services;
				const service = services[key];
				if (!service.enabled) {
					delete services[key];
				}
			}
		}
		return services;
	}

	categoryAvailabilityCheck = (
		category: T.Schema.Restaurant.Menu.RestaurantCategory,
		orderConfig: T.Schema.Order.OrderConfig
	): AvailabilityCheck => {
		if (!orderConfig.confirmed) return { available: true, reason: "" };
		if (!category) return { available: false, reason: "" };
		if (category.conditions) {
			if (category.conditions.services.length > 0) {
				if (
					category.conditions.services.indexOf(
						orderConfig.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
					) === -1
				) {
					return {
						available: false,
						reason: "services",
					};
				}
			}

			if (category.conditions.hours.length > 0) {
				const due =
					orderConfig.due === "later"
						? this.intl.momentFromFormat(
							`${orderConfig.date} ${orderConfig.time}`,
							FORMATS.moment.datetime
						)
						: this.intl.momentNow();

				const { isWithin } = RestaurantUtils.opening_hours.isTimeWithin({
					dt: due.clone(),
					timezone: this.intl.s.tz,
					hours: category.conditions.hours,
					last_offset: 0,
					first_offset: 0,
				});

				if (!isWithin) {
					return {
						available: false,
						reason: "hours",
					};
				}
			}
		}

		return { available: true, reason: "" };
	}

	filteredCategoriesMenus(menus: T.Schema.Restaurant.Menu.RestaurantMenu[]) {
		const orderConfig = this.order_config.s;
		let newMenus = cloneDeepSafe(menus);

		newMenus = newMenus.filter((menu) => {
			menu.categories = menu.categories.filter(
				(category) => !category.conditions?.hide_unavailable_category ||
					this.categoryAvailabilityCheck(category, orderConfig).available
			);
			return !!menu.categories.length;
		});
		return newMenus;
	}

	@computed get menus() {
		const menus = this.restaurant.menus;
		const oc = this.order_config.s;
		const c = this.customer.s.item;

		const filteredUnavailableMenus = menus.filter(
			(menu) =>
				!menu.conditions.hide_unavailable_menu ||
				this.menuAvailabilityCheck(menu, oc, c).available
		);

		return this.filteredCategoriesMenus(filteredUnavailableMenus);
	}

  @action updateRestaurant = (restaurant: T.Schema.Restaurant.RestaurantSafeSchema) => {
    const { restaurantSettings } = this.ably.s;
    if (restaurantSettings) {
      this.restaurant = restaurant; // restaurant is static
    }
    this.ably.update({restaurantSettings: null});
  }

	@computed get searchableDishes() {
		const menus = this.menus;

		let dishes: T.Schema.Restaurant.Menu.RestaurantDish[] = [];
		let categories: T.Schema.Restaurant.Menu.RestaurantCategory[] = [];
		for (const menu of menus) {
			const _menu = this.filteredHiddenDishes(menu);
			for (const category of _menu.categories) {
				categories.push(category);
				dishes = dishes.concat(category.dishes);
			}
		}

		return { categories, dishes };
	}

	filteredHiddenDishes = (menu: T.Schema.Restaurant.Menu.RestaurantMenu) => {
		const newMenu = cloneDeepSafe(menu);
		newMenu.categories = newMenu.categories.filter((category) => {
			category.dishes = category.dishes.filter((d) => d.status !== "hidden");
			return !!category.dishes.length;
		});
		return newMenu;
	};

	@computed get menu() {
		const { menus, filters } = this;

		const currentMenu = menus.find((m) => m._id === filters.s.menu);
		if (currentMenu) return this.filteredHiddenDishes(currentMenu);
		else if (menus.length) return this.filteredHiddenDishes(menus[0]);
		else return;
	}

	getCategory(category_id: string) {
		const menu = this.menu;
		if (!(menu && category_id)) return;
		return menu.categories.find((c) => c._id === category_id);
	}

	doesCategoryHaveRestrictions(category: T.Schema.Restaurant.Menu.RestaurantCategory) {
		const categoryConditions = category.conditions;

		if (categoryConditions && !categoryConditions.hide_unavailable_category) {
			return (categoryConditions.hours.length > 0 || categoryConditions.services.length > 0)
		}
		return false;
	}

	@computed get doesFiltersCategoryHaveRestrictions() {
		const category = this.getCategory(this.filters.s.category);
		if (!category) return false;

		return this.doesCategoryHaveRestrictions(category);
	}

	isCategoryAvailable = (category: T.Schema.Restaurant.Menu.RestaurantCategory) => {
		return this.categoryAvailabilityCheck(category, this.order_config.s);
	}

	@computed get isFilterCategoryAvailable() {
		const category = this.getCategory(this.filters.s.category);

		if (!category)
			return {
				available: false,
				reason: "services",
			};

		const oc = this.order_config.s;
		return this.categoryAvailabilityCheck(category, oc);
	}

	@computed get doesMenuHaveRestrictions() {
		const menu = this.menu;
		if (!menu) return false;
		const r = this.restaurant;
		if (
			r.settings.business.age_verification.enabled &&
			menu.conditions.age_restricted
		)
			return true;
		if (menu.conditions.times.length > 0) return true;
		if (menu.conditions.services.length > 0) return true;
		if (menu.conditions.hours.length > 0) return true;
		if (menu.conditions.pre_order.enabled) return true;
		return false;
	}

	@computed get isMenuAvailable() {
		const menu = this.menu;
		if (!menu) {
			return {
				available: false,
				reason: "services",
			};
		}

		const oc = this.order_config.s;
		const c = this.customer.s.item;

		return this.menuAvailabilityCheck(menu, oc, c);
	}
	@computed get isMenuOrderable() {
		const menu = this.menu;
		if (!menu) {
			return {
				available: false,
				reason: "services",
			};
		}

		const oc = this.order_config.s;
		const c = this.customer.s.item;

		return this.menuOrderableCheck(menu, oc, c);
	}

	menuAvailabilityCheck = (
		menu: T.Schema.Restaurant.Menu.RestaurantMenu,
		oc: OrderConfigState,
		c: T.Schema.Customer.CustomerSchema | null
	): AvailabilityCheck => {
		const r = this.restaurant;

		if (!oc.confirmed) return { available: true, reason: "" };

		if (
			r.settings.business.age_verification.enabled &&
			menu.conditions.age_restricted
		) {
			if (!c || c.age_verification.status !== "approved") {
				return {
					available: false,
					reason: "age",
				};
			}
		}

		if (menu.conditions.times.length > 0) {
			if (
				menu.conditions.times.indexOf(
					oc.due as T.Core.Business.BusinessOrderingTimes
				) === -1
			) {
				return {
					available: false,
					reason: "times",
				};
			}
		}

		if (menu.conditions.services.length > 0) {
			if (
				menu.conditions.services.indexOf(
					oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
				) === -1
			) {
				return {
					available: false,
					reason: "services",
				};
			}
		}

		if (menu.conditions.hours.length > 0) {
			const due =
				oc.due === "later"
					? this.intl.momentFromFormat(
						`${oc.date} ${oc.time}`,
						FORMATS.moment.datetime
					)
					: this.intl.momentNow();
			const { isWithin } = RestaurantUtils.opening_hours.isTimeWithin({
				dt: due.clone(),
				timezone: this.intl.s.tz,
				hours: menu.conditions.hours,
				last_offset: 0,
				first_offset: 0,
			});
			if (!isWithin) {
				return {
					available: false,
					reason: "hours",
				};
			}
		}

		if (menu.conditions.pre_order.enabled) {
			if (oc.due !== "later") {
				return {
					available: false,
					reason: "pre_order",
				};
			}

			const { cutoff_time, days_in_advance } = menu.conditions.pre_order;

			const now = this.intl.momentNow();
			const due = this.intl.momentFromFormat(
				`${oc.date} ${oc.time}`,
				FORMATS.moment.datetime
			);
			const cutoff_date = due
				.clone()
				.subtract(days_in_advance, "days")
				.format(FORMATS.moment.date);
			const cutoff = this.intl.momentFromFormat(
				`${cutoff_date} ${cutoff_time}`,
				FORMATS.moment.datetime
			);
			if (now.isAfter(cutoff)) {
				return {
					available: false,
					reason: "pre_order",
				};
			}
		}

		return { available: true, reason: "" };
	};

	menuOrderableCheck = (
		menu: T.Schema.Restaurant.Menu.RestaurantMenu,
		oc: OrderConfigState,
		c: T.Schema.Customer.CustomerSchema | null
	): AvailabilityCheck => {
		const r = this.restaurant;

		if (
			r.settings.business.age_verification.enabled &&
			menu.conditions.age_restricted
		) {
			if (!c || c.age_verification.status !== "approved") {
				return {
					available: false,
					reason: "age",
				};
			}
		}

		if (menu.conditions.times.length > 0) {
			if (
				menu.conditions.times.indexOf(
					oc.due as T.Core.Business.BusinessOrderingTimes
				) === -1
			) {
				return {
					available: false,
					reason: "times",
				};
			}
		}

		if (menu.conditions.services.length > 0) {
			if (
				menu.conditions.services.indexOf(
					oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
				) === -1
			) {
				return {
					available: false,
					reason: "services",
				};
			}
		}

		if (menu.conditions.hours.length > 0) {
			const due =
				oc.due === "later"
					? this.intl.momentFromFormat(
						`${oc.date} ${oc.time}`,
						FORMATS.moment.datetime
					)
					: this.intl.momentNow();
			const { isWithin } = RestaurantUtils.opening_hours.isTimeWithin({
				dt: due.clone(),
				timezone: this.intl.s.tz,
				hours: menu.conditions.hours,
				last_offset: 0,
				first_offset: 0,
			});
			if (!isWithin) {
				return {
					available: false,
					reason: "hours",
				};
			}
		}

		if (menu.conditions.pre_order.enabled) {
			if (oc.due !== "later") {
				return {
					available: false,
					reason: "pre_order",
				};
			}

			const { cutoff_time, days_in_advance } = menu.conditions.pre_order;

			const now = this.intl.momentNow();
			const due = this.intl.momentFromFormat(
				`${oc.date} ${oc.time}`,
				FORMATS.moment.datetime
			);
			const cutoff_date = due
				.clone()
				.subtract(days_in_advance, "days")
				.format(FORMATS.moment.date);
			const cutoff = this.intl.momentFromFormat(
				`${cutoff_date} ${cutoff_time}`,
				FORMATS.moment.datetime
			);
			if (now.isAfter(cutoff)) {
				return {
					available: false,
					reason: "pre_order",
				};
			}
		}

		return { available: true, reason: "" };
	};

	@computed get dishes(): {
		dishes: T.Schema.Restaurant.Menu.RestaurantDish[];
		categories: T.Schema.Restaurant.Menu.RestaurantCategory[];
		tagsApplied: boolean;
	} {
		const { filters } = this;
		const menu = this.menu;

		if (!menu || menu.categories.length === 0) {
			return {
				dishes: [],
				categories: [],
				tagsApplied: false,
			};
		}
		let tagsApplied = false;
		const hideAllCategory = this.restaurant.website.sections.menus.dish_hide_all_category === true;

		// Handle the `All` menu category link that shows all the dishes in each Menu.
		if (filters.s.category === "" && !hideAllCategory) {
			let categories: T.Schema.Restaurant.Menu.RestaurantCategory[] = [];
			let dishes: T.Schema.Restaurant.Menu.RestaurantDish[] = [];
			for (const category of menu.categories) {
				dishes = dishes.concat(category.dishes);
				categories = categories.concat(
					category.dishes.map((d) => {
						if (d.tags.length > 0) tagsApplied = true;
						return category;
					})
				);
			}
			return { dishes, categories, tagsApplied };
		}
		// Handle showing dishes in each category
		else {
			const findThis = filters.s.category !== "" ? filters.s.category : menu.categories[0]._id;
			const category = menu.categories.find((c) => c._id === findThis) || menu.categories[0];

			const dishes = category.dishes;
			const categories = dishes.map((d) => {
				if (d.tags.length > 0) tagsApplied = true;
				return category;
			});
			return { dishes, categories, tagsApplied };
		}
	}

	@computed get service() {
		const r = this.restaurant;
		const oc = this.order_config.s;
		if (!oc.service) return null;
		return r.settings.services[oc.service];
	}

	@computed get serviceOrderingEnabled() {
		const s = this.restaurant.settings.services;
		return s.pickup.enabled || s.delivery.enabled || s.dine_in.enabled;
	}

	@computed get serviceBookingEnabled() {
		return this.restaurant.settings.services.table_booking.enabled;
	}

	@computed get serviceDeliveryZones() {
		return (this.restaurant.settings.services.delivery.zones || []).filter(
			(zone) => !zone.disabled
		);
	}

	@computed get servicePaused() {
		const { pausedServices } = this.ably.s;
		const ops = this.restaurant.settings.business.operations;
		const services = this.restaurant.settings.services;
		const now = this.intl.momentNow();
		const isWithinTimeRange = (start: number, end: number) => 
		  now.isAfter(this.intl.momentFromMillis(start)) && now.isBefore(this.intl.momentFromMillis(end));
		const enabledServicesCount = Object.values(services).filter(opts => opts.enabled).length;
		let flag = false;
		if (!pausedServices) {
		  if (ops?.pause_start && ops?.pause_end) {
			flag = isWithinTimeRange(ops.pause_start, ops.pause_end) &&
				   ops.services.length === enabledServicesCount;
		  }
		} else if (pausedServices.status === 'paused' && pausedServices.pause_start && pausedServices.pause_end) {
		  flag = isWithinTimeRange(pausedServices.pause_start, pausedServices.pause_end) &&
				 pausedServices.services.length === enabledServicesCount;
		}
		return flag;
	  }

	isDishPaused = (dish: T.Schema.Restaurant.Menu.RestaurantDish) => {
		const now = this.intl.momentNow();
		let flag = false;
		if(dish.pause_infinite) 
			return true;
		if(dish.pause_start && dish.pause_end) {
			flag = now.isAfter(this.intl.momentFromMillis(dish.pause_start))
			&& now.isBefore(this.intl.momentFromMillis(dish.pause_end));
		}
		return flag;
	}

	@computed get orderingDisabled() {
		return this.restaurant.settings.business.ordering_disabled || false;
	}

	@computed get servicesAllDisabled(): boolean {
		const r = this.restaurant;
		const now = this.intl.momentNow();
		logger.info(
			`MOMENT: ${now.format(this.intl.formats.datetime)} - ${now.valueOf()}`
		);
		for (const serviceKey in r.settings.services) {
			if (r.settings.services.hasOwnProperty(serviceKey)) {
				const key = serviceKey as keyof typeof r.settings.services;
				const service = r.settings.services[key];
				if (service.enabled) {
					if (service.times.later) {
						return false;
					}
					const {
						first_order_offset,
						last_order_offset,
					} = service.times.conditions;
					const hours =
						service.hours?.length > 0 ? service.hours : r.location.opening_hours;
					const open = RestaurantUtils.opening_hours.isTimeWithin({
						dt: now.clone(),
						timezone: this.intl.s.tz,
						hours: hours,
						special_hours: r.location.special_hours,
						first_offset: first_order_offset || 0,
						last_offset: last_order_offset || 0,
					}).isWithin;
					if (open) {
						logger.info('2');
						return false;
					}
				}
			}
		}
		return true;
	}

	@computed get servicesEnabledCount(): number {
		const r = this.restaurant;
		let count = 0;
		for (const serviceKey in r.settings.services) {
			if (r.settings.services.hasOwnProperty(serviceKey)) {
				const key = serviceKey as keyof typeof r.settings.services;
				const service = r.settings.services[key];
				if (service.enabled) {
					count++;
				}
			}
		}
		return count;
	}

	// DOES NOT AFFECT ORDERS FOR A LATER DATE
	serviceOpen = (): boolean => {
		const now = this.intl.momentNow();

		const r = this.restaurant;

		const service = this.service;

		if (!service) return true;

		const { first_order_offset, last_order_offset } = service.times.conditions;

		const hours =
			service.hours?.length > 0 ? service.hours : r.location.opening_hours;

		return RestaurantUtils.opening_hours.isTimeWithin({
			dt: now.clone(),
			timezone: this.intl.s.tz,
			hours: hours,
			special_hours: r.location.special_hours,
			first_offset: first_order_offset || 0,
			last_offset: last_order_offset || 0,
		}).isWithin;
	};

	// DOES NOT AFFECT ORDERS FOR A LATER DATE
	serviceClosesIn = (): null | { minutes: number; millis: number } => {
		const now = this.intl.momentNow();
		const r = this.restaurant;
		const oc = this.order_config.s;
		const service = this.service;
		if (!service || !oc.due) return null;

		const { first_order_offset, last_order_offset } = service.times.conditions;
		const hours =
			service.hours?.length > 0 ? service.hours : r.location.opening_hours;
		return RestaurantUtils.opening_hours.closesIn({
			dt: now.clone(),
			timezone: this.intl.s.tz,
			hours: hours,
			special_hours: r.location.special_hours,
			first_offset: first_order_offset || 0,
			last_offset: last_order_offset || 0,
		});
	};

	@computed get order_id() {
		const path = this.router.s.path;
		return path.indexOf("/order/") !== -1 ? path.split("/order/")[1] : null;
	}

	@computed get booking_id() {
		const path = this.router.s.path;
		return path.indexOf("/booking/") !== -1 ? path.split("/booking/")[1] : null;
	}

	getRootState = () => {
		return {
			reseller: this.reseller,
			restaurant: this.restaurant,
			billing: this.billing,
			restaurantStock: this.restaurantStock,
			order_config: toJS(this.order_config.s),
			filters: toJS(this.filters.s),
			dish: toJS(this.dish.s),
			cart: toJS(this.cart.s),
			checkout: toJS(this.checkout.s),
			view: toJS(this.view.s),
			modal: toJS(this.modal.s),
			loader: toJS(this.loader.s),
			router: toJS(this.router.s),
			customer: toJS(this.customer.s),
			customerForm: toJS(this.customer.form),
		};
	};

	serialize = (): string => {
		return stringify(this.getRootState());
	};

	getCompleteSession = (meta: T.ObjectString = {}) => {
		return {
			created: Date.now(),
			customer: toJS(this.customer.s),
			order_config: toJS(this.order_config.s),
			filters: toJS(this.filters.s),
			dish: toJS(this.dish.s),
			cart: toJS(this.cart.s),
			checkout: toJS(this.checkout.s),
			view: toJS(this.view.s),
			modal: toJS(this.modal.s),
			loader: toJS(this.loader.s),
			// router: toJS(this.router.s),
			meta: meta,
		};
	};

	getCompleteSessionNoView = () => {
		return {
			created: Date.now(),
			customer: toJS(this.customer.s),
			order_config: toJS(this.order_config.s),
			filters: toJS(this.filters.s),
			dish: toJS(this.dish.s),
			cart: toJS(this.cart.s),
			checkout: toJS(this.checkout.s),
			modal: toJS(this.modal.s),
			loader: toJS(this.loader.s),
		};
	};

	sessionRemoveCart = () => SessionStorage.remove("cart-session");

	sessionSave = (ref: string, data: T.ObjectAny) => {
		if (ref !== "cart-session") {
			this.sessionRemoveCart();
		}
    if (data.order_config && data.order_config.is_from_qr_code) {
      delete data.order_config.is_from_qr_code;
    }
		SessionStorage.set(ref, stringify(data));
	};

	sessionRestore = (ref: string, expiry?: number) => {
		const data = SessionStorage.get(ref);
		if (data) {
			if (data.created && Date.now() > data.created + expiry) {
				return false;
			}
			/* UGLY: Support DineIn Table Switching.
					 Do not restore table_info from cart-session if table_id is existing in store.order_config.
					 In case when the customer changed tables.

					 Had to do it this way to not impact multiple payment channel callbacks (pesapal/bambora)
					 embedded in the restoreSavedSession method.
			*/
			const is_table = (this.order_config && this.order_config.s.table_id) ? true : false;
			const has_switched_tables = is_table && (this.order_config.s.table_id !== data.order_config.table_id);
			if (is_table) {
				const newState = _omit(data.order_config, ["service", "due", "date", "time", "confirmed", "table_id", "table", "table_password"]);
				this.order_config.update(newState);
			} else {
				this.order_config.update(data.order_config);
			}

			// NON-DINEIN ORDERS AND SAME TABLES, RESTORE CART DATA
			if (!has_switched_tables) {

				// check the availability of menu and category
				this.validateFilterDatas(data.filters);

				this.dish.update(data.dish);
				this.cart.update(data.cart);
				this.checkout.update(data.checkout);
				if (data.view) {
					this.view.update(data.view);
				}
				if (this.router.s.path === "/") {
					this.modal.update(data.modal);
				}
			}
			this.loader.update(data.loader);
			this.customer.update(data.customer);
			// this.router.update(data.router);
			// tslint:disable-next-line
			return data.meta || true;
		}
		return false;
	};

	sessionRemoveOrder = () => {
		this.order.reset();
	}

	printSessionList = () => {
		SessionStorage.list();
	};

	validateFilterDatas(filters: FiltersState) {

		const restoredMenu = this.menus.find((menu) => menu._id === filters.menu);

		if (restoredMenu) {
			const restoredCategory = restoredMenu.categories.find((category) => category._id === filters.category);

			if (restoredCategory) {
				this.filters.update(filters);

				if (
					this.view.itemLayoutStyle === "divided-into-categories" &&
					this.filters.s.category
				) {
					// this.view.scrollToCategory(this.filters.s.category);
				}

			} else if (restoredMenu.categories.length > 0) {
				this.filters.update({
					menu: restoredMenu._id,
					category: ""
				});
			}
		} else if (this.menus.length > 0) {
			this.filters.update({
				menu: this.menus[0]._id,
				category: ""
			});
		} else {
			this.filters.update({
				menu: "",
				category: ""
			});
		}
	}

	@action refreshRestaurant = async ()=>{
		const result = await this.api.getRestaurant({_id:this.restaurant._id});
		if(result.outcome === 0){
				this.restaurant = result.restaurant;
		}
	}

	@action updateRestaurantOperations = (operations : T.Schema.Restaurant.RestaurantOperations)=>{
		const restaurant = {...this.restaurant}
		restaurant.settings.business.operations = operations;
		this.restaurant = restaurant;
	}

	@action refreshRestaurantMenuOperations = async ()=>{	
		const result = await this.api.getRestaurant({_id:this.restaurant._id});
		if(result.outcome === 0){
				this.restaurant.menus = result.restaurant.menus;
		}
	}
}
