import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FaExclamationCircle } from 'react-icons/fa';
import { CartPromos } from './promos';
import { CartTipping } from './tipping';
import { OrderTotals, OrderItems, Modal, ModalContent, ModalTitle, Button, styled, RotateLoader } from '@lib/components';
import { toJS } from 'mobx';
import { FreeItem, Promo } from './type';
import { getCurrencyPrecision, roundedNum } from '@lib/common';
import { useStores } from '../../../mobx/useStores';
import useVersionChecker from './useVersionChecker';
import { isEmpty } from 'lodash';

const CheckoutButtonWrapper = styled('div')<{ isMobileFullScreen?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-color: ${theme => theme.theme.box.background};
    position: ${props => (props.isMobileFullScreen ? 'fixed' : 'relative')};
    bottom: 0;
    left: 0;
    right: 0;
    & > div {
      padding: 0;
    }

    & button {
      border-radius: 0;
    }
  }
`;

export const CartModal = observer(() => {
  const store = useStores();
  const { t } = useTranslation();
  const { isVersionChanged, fetchLatestVersions } = useVersionChecker();

  useEffect(() => {
    if (isVersionChanged) {
      store.modal.hide('cart');
      store.modal.show('app-refresh');
    }
  }, [isVersionChanged]);

  const checkout = async () => {
    if(store.cart.form.promo.code) {
      await store.cart.promoCodeApply();
      return 
    }
    if (await fetchLatestVersions()) {
      return;
    }

    store.checkout.update({ checkout_fields: [], error: '' });

    await store.cart.promoCodeApply();
    if (store.cart.form.promo.error || !store.cart.minOrderDeliveryValid) return;

    const promo = store.cart.s.promos && store.cart.s.promos.length > 0 ? store.cart.s.promos[0] : null;
    
    const isSelectedFreeItem = localStorage.getItem('isSelectedFreeItem') === 'true';
    const checkForFreeItem = (promo as T.Schema.Restaurant.Promo.RestaurantPromo)?.free_dishes && !isEmpty((promo as T.Schema.Restaurant.Promo.RestaurantPromo)?.free_dishes) // revert for legacy promo code to no popup for free item to reconsider

    if (promo && ((promo as FreeItem).type === 'free_item')) {
      if (isSelectedFreeItem) {
        store.modal.show('checkout');
      } else {
        store.modal.show('selectFreeItems');
      }
    } else {
      store.modal.show('checkout');
    }

    fbq('track', 'InitiateCheckout', {
      value: store.cart.totalCartWithDiscount,
      num_items: store.cart.itemsInCart,
      currency: store.restaurant.settings.region.currency.code,
    });
  };

  const restaurant = store.restaurant;
  const tax_in_prices = store.restaurant.settings.region.tax.in_prices;
  const service = store.order_config.s.service;
  const df = store.restaurant.settings.services.delivery.fee;
  const cart = store.cart;
  const items = cart.s.items;
  const isFullScreen = store.restaurant.website.sections?.mobiles?.mobile_fullscreen;
  const precision = getCurrencyPrecision(store.intl.s.currency.code, store.intl.s.currency.precision);

  const changeableItems = toJS(items);
  const itemsTaxIndicator = changeableItems.map((item: any) => {
    restaurant.settings.region.tax.rates?.forEach(rate => {
      if (rate.tax_indicator_flag && rate.dish_tax && rate.dish_tax.indexOf(item._id) === -1) {
        item.name = item.name + rate.tax_indicator_character;
      }
    });
    return item;
  });

  const tipping = restaurant.settings.business.tipping;
  const proceedToCheckoutValid = cart.minOrderDeliveryValid;
  const isTippingEnabled = !!tipping?.enabled && service !== '' && !tipping.disabled_services?.includes(service);
  return (
    <Modal
      id="modal-cart"
      width={420}
      closeButton={true}
      active={store.modal.isVisible('cart')}
      isFullScreen={isFullScreen}
      close={() => store.modal.toggle('cart')}
    >
      {items.length === 0 && (
        <ModalContent paddinglr={25} paddingtb={55} className="round-bottom-sm">
          <div className="text-center">
            <p className="big">{t('store.modals.cart.no_items_1')}</p>
            <p className="small m-t-2">{t('store.modals.cart.no_items_2')}</p>
          </div>
        </ModalContent>
      )}

      {items.length > 0 && (
        <div>
          <ModalTitle paddinglr={25} paddingtb={20} className="round-top">
            <div>
              <h4>{t('store.modals.cart.title')}</h4>
              {!!(df.free_delivery_minimum && service === 'delivery') && (
                <p className="small lhp">
                  {t('store.modals.cart.free_delivery_notice', {
                    value: df.free_delivery_minimum,
                  })}
                </p>
              )}
            </div>
          </ModalTitle>

          <ModalContent paddinglr={25} paddingtb={25}>
            <OrderItems
              items={itemsTaxIndicator}
              itemContent={(dish, i) => (
                <div className="m-t-2">
                  {!dish.isFreeItems && (
                    <a onClick={() => store.cart.edit(i)} className="primary-text inline-block smaller m-r-2">
                      {t('store.modals.cart.edit_dish')}
                    </a>
                  )}
                  <a onClick={() => store.cart.remove(i)} className="primary-text inline-block smaller">
                    {t('store.modals.cart.remove_dish')}
                  </a>
                </div>
              )}
            />
            <OrderTotals
              tax_in_prices={tax_in_prices}
              fees={cart.fees}
              taxes={cart.taxes}
              discount={cart.discount}
              promo={cart.promo as Promo}
              totalCart={cart.totalCart}
              total={cart.total}
              tip={cart.s.tip === '' ? 0 : parseFloat(roundedNum(Number(cart.s.tip), precision).toString())}
            />
            {restaurant.settings.region.tax.rates?.map((rate, index) => {
              {
                return rate.tax_indicator_flag ? (
                  <p key={index} className="small" style={{ marginTop: '10px' }}>
                    {rate.tax_indicator_string}
                  </p>
                ) : (
                  <div key={index}></div>
                );
              }
            })}
          </ModalContent>

          {isTippingEnabled && <CartTipping />}

          {/* {store.cart.hasAvailablePromo > 0 && ( */}
            <>
              <ModalTitle paddinglr={25} paddingtb={15}>
                <h4 className="font16">{t('store.modals.cart.promo.heading')}</h4>
              </ModalTitle>

              <CartPromos onClickPlusButton={checkout}/>
            </>
          {/* )} */}

          {!cart.minOrderDeliveryValid && (
            <ModalContent key="error" className="error-bg" paddinglr={25} paddingtb={12}>
              <p className="small lhp">
                <FaExclamationCircle className="m-r-2" />
                {t('store.modals.cart.errors.min_order_delivery', {
                  value: cart.minOrderDelivery,
                })}
              </p>
            </ModalContent>
          )}

          <CheckoutButtonWrapper isMobileFullScreen={isFullScreen}>
            <ModalContent paddinglr={25} paddingtb={25}>
              <Button full={true} color="primary" onClick={checkout} disabled={!proceedToCheckoutValid || cart.s.isApplyingPromoCode}>
                {cart.s.isApplyingPromoCode && <RotateLoader size={2} /> }
                {!cart.s.isApplyingPromoCode && t('store.modals.cart.proceed_to_checkout') }
              </Button>
            </ModalContent>
          </CheckoutButtonWrapper>
        </div>
      )}
    </Modal>
  );
});
